.bg-color {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(34, 64, 154, 1) 35%,
    rgba(155, 228, 242, 1) 100%
  );
  color: white;
}

ul > li {
  list-style-type: none;
  text-align: left;
}

.div1 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(34, 64, 154, 1) 35%,
    rgba(155, 228, 242, 1) 100%
  );
  color: white;
  padding: 10px 30px;
}

.div2 {
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 0%,
    rgba(34, 64, 154, 1) 35%,
    rgba(155, 228, 242, 1) 100%
  );
  color: white;
  padding: 10px 30px;
}

.cCss {
  background-color: white;
}

.cp {
  font-weight: bold;
  font-size: 2rem;
}

.box-head {
  font-weight: bold;
  text-transform: uppercase;
}

.box-num {
  font-weight: bolder;
  font-size: 2rem;
}

.three-box {
  border: 1px solid #32a2d7;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.tClass {
  width: 800px;
  background: #f5fdff !important;
  margin-left: auto;
  margin-right: auto;
}

.tInvest {
  background: #22409a;
  border-radius: 5px;
}

.tText {
  font-weight: bold;
  font-size: 1.2rem;
  color: white;
}
.tText2 {
  font-weight: bold;
  font-size: 1.5rem;
}

.tbgti:first-child {
  background: #22409a;
  border-radius: 8px;
}

.tbgti:nth-child(2) {
  background: #4f7abd;
  border-radius: 8px;
}

.tbgti:nth-child(3) {
  background: #2da1d9;
  border-radius: 8px;
}

.tbgti:nth-child(4) {
  background: #69b3c6;
  border-radius: 8px;
}
.tracking-wide {
  margin-bottom: 20px;
}

.tbrc {
  border-color: #5db8e3;
}

.tbrc2 {
  border-color: #b5ddb7;
}

.tbg {
  background: linear-gradient(to right, #22409a, #8ecde6);
}

.bgC {
  background: #f5fdff !important;
}

.imC:first-child {
  content: url(img/grow.png);
}

.imC:nth-child(2) {
  content: url(img/pie.png);
}
footer cite {
  float: right;
}
